<template>
  <!--  <div @dragover="() => onSectionDragenter()" @dragleave="() => onSectionDragleave()">-->
  <div @dragenter="onSectionDragenter" @dragleave="onSectionDragleave" @dragover="onSectionDragover">
    <div class="ghost-content px-4">
      <span class="ghost-from-bullet">
        <i class="bi bi-grip-vertical fs-3" />
      </span>
      <span class="ghost-title">{{ title || 'No title' }}</span>
    </div>
    <div class="ghost-helper ghost-helper-d-block" />
    <div class="ghost-change-order-possible-block__hidden card border-bottom rounded-top-0 rounded-bottom-0 mb-5">
      <div class="card-header position-relative align-items-center border-0 ps-8 pe-0">
        <div class="position-absolute top-50 start-0 translate-middle-y d-inline-flex align-self-center">
          <slot name="header-left" />
        </div>
        <div class="d-flex align-items-center">
          <div class="cursor-pointer p-2">
            <!--begin::Svg Icon-->
            <span
              :class="[
                'svg-icon svg-icon-2hx',
                { 'svg-icon-muted accordion-icon-not-expanded': !isOpened },
                { 'svg-icon-primary accordion-icon-expanded': isOpened },
              ]"
              role="button"
              @click="() => onToggle()"
            >
              <inline-svg v-if="isOpened" src="/assets/media/icons/duotune/general/gen036.svg" />
              <inline-svg v-else src="/assets/media/icons/duotune/general/gen035.svg" />
            </span>
            <!--end::Svg Icon-->
          </div>
          <slot name="header-control" />
        </div>
        <slot name="header-right" />
      </div>
      <div v-if="isOpened">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
// import {debounce} from 'lodash';

export default {
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
    isOpenedByDragenter: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    onToggle: {
      type: Function,
      required: true,
    },
    onDragenter: {
      type: Function,
    },
  },
  data() {
    return {
      dragoverTimerId: null,
      dragoverTimerValue: 0,
    };
  },
  methods: {
    dragoverTimerOn() {
      this.dragoverTimerId = setInterval(() => {
        this.dragoverTimerValue += 1;
      }, 1);
    },
    dragoverTimerOff() {
      clearInterval(this.dragoverTimerId);
      this.dragoverTimerValue = 0;
    },
    onSectionDragenter() {
      if (!this.isOpenedByDragenter) return false;
      if (this.isOpened) return false;

      this.dragoverTimerOff();

      setTimeout(() => {
        this.dragoverTimerOn();
      });
    },
    onSectionDragover() {
      if (!this.isOpenedByDragenter) return false;
      if (this.isOpened) return false;

      if (this.dragoverTimerValue > 100) {
        this.$emit('on-dragenter');
        this.dragoverTimerOff();
      }
    },
    onSectionDragleave() {
      if (!this.isOpenedByDragenter) return false;
      if (this.isOpened) return false;

      this.dragoverTimerOff();
    },
  },
};
</script>
