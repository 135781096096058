import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      updateInProcess: false,
      prevInProcess: false,
      nextInProcess: false,
    };
  },
  computed: {
    ...mapGetters({
      topMenu: 'proposalWizard/topMenu',

      getterCurrentProposal: 'proposalWizard/currentProposal',

      getterState: 'proposalWizard/state',
      getterScopeType: 'proposalWizard/scopeType',
      getterConstruction: 'proposalWizard/construction',
      getterCustomShapeText: 'proposalWizard/customShapeText',
      getterShape: 'proposalWizard/shape',
      getterSizeCategory: 'proposalWizard/sizeCategory',
      getterWidth: 'proposalWizard/width',
      getterLength: 'proposalWizard/length',
      getterShallow: 'proposalWizard/shallow',
      getterDeepend: 'proposalWizard/deepend',
      getterIntroduction: 'proposalWizard/introduction',
      getterConstructionDetails: 'proposalWizard/constructionDetails',
      getterSpecialNotes: 'proposalWizard/specialNotes',
      getterPricingAndPaymentInformation: 'proposalWizard/pricingAndPaymentInformation',
      getterTermsConditionsWarranties: 'proposalWizard/termsConditionsWarranties',
      getterVersion: 'proposalWizard/version',
      getterDate: 'proposalWizard/date',
      getterValue: 'proposalWizard/value',
      getterStatus: 'proposalWizard/status',
      getterInclusions: 'proposalWizard/inclusions',
      getterPricingSections: 'proposalWizard/pricingSections',
      getterEquipmentItems: 'proposalWizard/equipmentItems',
      getterPaymentScheduleItems: 'proposalWizard/paymentScheduleItems',
      getterSubTotal: 'proposalWizard/subTotal',
      getterUsers: 'proposalWizard/users',
      getterPlantingSections: 'proposalWizard/plantingSections',
    }),
  },
  methods: {
    ...mapMutations({
      mutationCurrentRoute: 'proposalWizard/SET_CURRENT_ROUTE',
    }),
    checkIfProposalWasUpdated() {
      const proposalAttributes = this.getterCurrentProposal.attributes;

      // console.log(
      //   JSON.stringify(this.getterPricingSections) === JSON.stringify(proposalAttributes.pricing_sections.data)
      // );
      // console.log(JSON.stringify(this.getterPricingSections));
      // console.log(JSON.stringify(proposalAttributes.pricing_sections.data));

      const checks = [
        this.getterState === proposalAttributes.state,
        this.getterScopeType === proposalAttributes.scope_type,
        this.getterConstruction === proposalAttributes.construction,
        this.getterShape === proposalAttributes.shape,
        this.getterCustomShapeText === proposalAttributes.custom_shape_text,
        this.getterSizeCategory === proposalAttributes.size_category,
        `${this.getterWidth}` === `${proposalAttributes.width}`,
        `${+this.getterLength}` === `${+proposalAttributes.length}`,
        `${this.getterShallow}` === `${proposalAttributes.shallow}`,
        `${this.getterDeepend}` === `${proposalAttributes.deepend}`,
        this.getterIntroduction === proposalAttributes.introduction,
        this.getterConstructionDetails === proposalAttributes.construction_details,
        this.getterSpecialNotes === proposalAttributes.special_notes,
        this.getterPricingAndPaymentInformation === proposalAttributes.pricing_and_payment_information,
        this.getterTermsConditionsWarranties === proposalAttributes.terms_conditions_warranties,
        this.getterVersion === proposalAttributes.version,
        this.getterDate === proposalAttributes.date,
        +this.getterValue === proposalAttributes.value,
        this.getterStatus === proposalAttributes.status,
        JSON.stringify(this.getterInclusions) === JSON.stringify(proposalAttributes.inclusions.data),
        JSON.stringify(this.getterPricingSections) === JSON.stringify(proposalAttributes.pricing_sections.data),
        JSON.stringify(this.getterEquipmentItems) === JSON.stringify(proposalAttributes.equipment_items.data),
        JSON.stringify(this.getterPaymentScheduleItems) ===
          JSON.stringify(proposalAttributes.payment_schedule_items.data),
        JSON.stringify(this.getterUsers) === JSON.stringify(proposalAttributes.users.data),
        JSON.stringify(this.getterPlantingSections) === JSON.stringify(proposalAttributes.planting_sections.data),
      ];

      return checks.includes(false);
    },
    async onUpdate() {
      this.updateInProcess = true;

      await this._onUpdate();

      this.updateInProcess = false;
    },
    goToPrev(validation) {
      let route = null;

      if (validation && typeof validation === 'function' && validation() === false) {
        return false;
      }

      this.prevInProcess = true;

      this.topMenu.map((el, i) => {
        if (el.pathName === this.$route.name) {
          route = this.topMenu[i - 1] || this.topMenu[i];
        }
      });

      if (!this.checkIfProposalWasUpdated()) {
        this._goTo(route);
      } else {
        this._onUpdate().then(() => {
          setTimeout(() => {
            this.prevInProcess = false;

            this._goTo(route);
          }, 1000);
        });
      }
    },
    goToNext(validation) {
      let route = null;

      if (validation && typeof validation === 'function' && validation() === false) {
        return false;
      }

      this.nextInProcess = true;

      this.topMenu.map((el, i) => {
        if (el.pathName === this.$route.name) {
          route = this.topMenu[i + 1] || this.topMenu[i];
        }
      });

      if (!this.checkIfProposalWasUpdated()) {
        this._goTo(route);
      } else {
        this._onUpdate().then(() => {
          setTimeout(() => {
            this.nextInProcess = false;

            this._goTo(route);
          }, 1000);
        });
      }
    },
    goToPath(path, validation) {
      if (path.pathName === this.$route.name) {
        return false;
      }

      if (!this.checkIfProposalWasUpdated()) {
        this._goTo(path);
      } else {
        this._onUpdate(validation).then(() => {
          setTimeout(() => this._goTo(path), 1000);
        });
      }
    },
    _goTo(route) {
      this.$router
        .push({
          name: route.pathName,
          params: {
            id: this.itemId,
          },
        })
        .catch((err) => console.log(err));
    },
    async _onUpdate(validation) {
      if (validation && typeof validation === 'function' && validation() === false) {
        return false;
      }

      this.mutationCurrentRoute(this.$route.name);

      await this.megaUpdate(this.itemId);
      await this.v_toast(this.message?.status, 'Updated successfully!');
    },
  },
};
