import ProposalWizardNavMixin from '@/mixins/ProposalWizardNavMixin';

export default {
  props: {
    scopeType: String,
    itemId: String,
  },
  data() {
    return {};
  },
  mixins: [ProposalWizardNavMixin],
  methods: {
    normalizeString(str) {
      if (str) {
        return str.startsWith('"') ? JSON.parse(str) : str;
      }

      if (str === null) {
        return '';
      }
    },
    goTo(routes) {
      const { swimmingPool = '/', woodworking = '/', landscaping = '/' } = routes;
      const route = () => {
        switch (this.scopeType) {
          case 'Swimming Pool':
            return swimmingPool;
          case 'Woodworking':
            return woodworking;
          case 'Landscaping':
            return landscaping;
          default:
            return false;
        }
      };

      this.$router.push({
        name: route(),
        params: {
          id: this.itemId,
        },
      });
    },
  },
};
