var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.filterText && _vm.filteredCategories.size)?_c('div',{staticClass:"menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"},[_c('div',{staticClass:"my-5 my-lg-5"},_vm._l((_vm.filteredCategories.keys()),function(category){return _c('div',{key:category},[(!_vm.isMapNotEmpty(_vm.filteredCategories.get(category)))?_c('div',[_c('div',{class:['menu-item menu-accordion', { show: category === _vm.innerCurrentCategory }]},[_c('div',{staticClass:"menu-link py-2 px-0",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.changeCurrentCategory(category); }).apply(null, arguments)}}},[_c('span',{staticClass:"menu-title fs-8 fw-semi-bold text-uppercase text-ls-sm text-muted"},[_vm._v(" "+_vm._s(category)+" ")]),_c('span',{staticClass:"menu-arrow"})])]),(_vm.innerCurrentCategory === category)?_c('div',_vm._l((_vm.filteredCategories.get(category).keys()),function(subCategory){return _c('div',{key:subCategory,class:['menu-item menu-accordion', { show: subCategory === _vm.innerCurrentSubCategory }]},[(_vm.filteredCategories.get(category).get(subCategory).length)?_c('div',[_c('div',{staticClass:"menu-link py-2",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.changeCurrentSubCategory(subCategory); }).apply(null, arguments)}}},[_c('span',{staticClass:"menu-title fs-6"},[_vm._v(" "+_vm._s(subCategory)+" ")]),_c('span',{staticClass:"menu-arrow"})]),(subCategory === _vm.innerCurrentSubCategory)?_c('draggable',{staticClass:"mh-400px scroll-y",attrs:{"handle":".menu-bullet","sort":false,"list":_vm.filteredCategories.get(category).get(subCategory),"group":{
                    name: 'sub-category-items',
                    pull: 'clone',
                    put: false,
                  },"clone":_vm.cloneCallback}},_vm._l((_vm.filteredCategories.get(category).get(subCategory)),function(item){return _c('div',{key:item.id,staticClass:"kt-items-list-item menu-item"},[_c('td',{staticClass:"ghost-helper ghost-helper-d-table-cell ghost-helper-space-start",attrs:{"colspan":"9"}}),_c('div',{staticClass:"ghost-content px-4"},[_c('span',{staticClass:"ghost-from-bullet"},[_c('i',{staticClass:"bi bi-grip-vertical fs-3"})]),_c('span',{staticClass:"ghost-title"},[_vm._v(" "+_vm._s(item.type === 'pricing_item_template' ? item.attributes.name : item.attributes.english_name)+" ")])]),_c('div',{staticClass:"ghost-hidden position-relative"},[_c('div',{staticClass:"handle d-flex align-items-start menu-link py-2 ps-6 pe-4",attrs:{"role":"button"}},[_c('span',{staticClass:"menu-bullet"},[_c('i',{staticClass:"bi bi-grip-vertical fs-3"})]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"menu-title fw-semi-bold pe-15"},[_vm._v(" "+_vm._s(item.type === 'pricing_item_template' ? item.attributes.name : item.attributes.english_name)+" "),(item.type === 'planting_item_template' && item.attributes.price_variables)?_c('span',{staticClass:"ghost-hidden"},[(
                                  item.attributes.price_variables.length &&
                                  item.attributes.price_variables.length !== 1 &&
                                  item.attributes.price_variables[0].id !== '0'
                                )?_c('span',{staticClass:"mx-2"},[_vm._v(" • ")]):_vm._e(),(
                                  item.attributes.price_variables.length &&
                                  item.attributes.price_variables.length !== 1 &&
                                  item.attributes.price_variables[0].id !== '0'
                                )?_c('span',[_vm._v(" "+_vm._s(item.attributes.price_variables[0].size)+" ")]):_vm._e()]):_vm._e()]),_c('span',[_vm._v(" "+_vm._s(_vm.$n( item.attributes.price_variables && item.attributes.price_variables.length ? item.attributes.price_variables[0].price : item.attributes.price, 'currency', 'en-CA' ))+" ")]),_c('div',{staticClass:"text-muted small text-truncate text-truncate-3"},[_vm._v(" "+_vm._s(item.attributes.description)+" ")])])]),_c('div',{staticClass:"position-absolute top-0 end-0 d-flex align-items-center justify-content-end mt-2"},[_c('px-btn',{attrs:{"color":"light-primary","extended-classes":"btn-icon w-30px h-30px p-0"},nativeOn:{"click":function($event){return (function () { return _vm.actionAddingToLast(item); }).apply(null, arguments)}}},[_c('span',{staticClass:"svg-icon svg-icon-2x"},[_c('inline-svg',{attrs:{"src":"/assets/media/icons/duotune/arrows/arr075.svg"}})],1)]),_c('px-dropdown',{attrs:{"items":[
                            {
                              title: 'Edit',
                              action: function () { return _vm.actionEditTemplate(item); },
                            },
                            {
                              title: 'Delete',
                              action: function () { return _vm.actionDeleteTemplate(item); },
                            } ]}})],1)])])}),0):_vm._e()],1):_vm._e()])}),0):_vm._e()]):_vm._e()])}),0)]):(_vm.filterText && _vm.searchCategories.length)?_c('div',[_c('div',{staticClass:"my-5 my-lg-5"},[_c('draggable',{staticClass:"mh-400px scroll-y filtered-container",attrs:{"handle":".menu-bullet","sort":false,"list":_vm.searchCategories,"group":{
          name: 'sub-category-items',
          pull: 'clone',
          put: false,
        },"clone":_vm.cloneCallback},nativeOn:{"scroll":function($event){return _vm.handleOnSerachResultsScroll.apply(null, arguments)}}},_vm._l((_vm.searchCategories),function(item){return _c('div',{key:item.id,staticClass:"kt-items-list-item menu-item"},[_c('td',{staticClass:"ghost-helper ghost-helper-d-table-cell ghost-helper-space-start",attrs:{"colspan":"9"}}),_c('div',{staticClass:"ghost-content px-4"},[_c('span',{staticClass:"ghost-from-bullet"},[_c('i',{staticClass:"bi bi-grip-vertical fs-3"})]),_c('span',{staticClass:"ghost-title"},[_vm._v(" "+_vm._s(item.type === 'pricing_item_template' ? item.attributes.name : item.attributes.english_name)+" ")])]),_c('div',{staticClass:"ghost-hidden position-relative"},[_c('div',{staticClass:"handle d-flex align-items-start menu-link py-2 ps-6 pe-4",attrs:{"role":"button"}},[_c('span',{staticClass:"menu-bullet"},[_c('i',{staticClass:"bi bi-grip-vertical fs-3"})]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"menu-title fw-semi-bold pe-15"},[_vm._v(" "+_vm._s(item.type === 'pricing_item_template' ? item.attributes.name : item.attributes.english_name)+" "),(item.type === 'planting_item_template' && item.attributes.price_variables)?_c('span',{staticClass:"ghost-hidden"},[(
                        item.attributes.price_variables.length &&
                        item.attributes.price_variables.length !== 1 &&
                        item.attributes.price_variables[0].id !== '0'
                      )?_c('span',{staticClass:"mx-2"},[_vm._v(" • ")]):_vm._e(),(
                        item.attributes.price_variables.length &&
                        item.attributes.price_variables.length !== 1 &&
                        item.attributes.price_variables[0].id !== '0'
                      )?_c('span',[_vm._v(" "+_vm._s(item.attributes.price_variables[0].size)+" ")]):_vm._e()]):_vm._e()]),_c('span',[_vm._v(" "+_vm._s(_vm.$n( item.attributes.price_variables && item.attributes.price_variables.length ? item.attributes.price_variables[0].price : item.attributes.price, 'currency', 'en-CA' ))+" ")]),_c('div',{staticClass:"text-muted small text-truncate text-truncate-3"},[_vm._v(" "+_vm._s(item.attributes.description)+" ")])])]),_c('div',{staticClass:"position-absolute top-0 end-0 d-flex align-items-center justify-content-end mt-2"},[_c('px-btn',{attrs:{"color":"light-primary","extended-classes":"btn-icon w-30px h-30px p-0"},nativeOn:{"click":function($event){return (function () { return _vm.actionAddingToLast(item); }).apply(null, arguments)}}},[_c('span',{staticClass:"svg-icon svg-icon-2x"},[_c('inline-svg',{attrs:{"src":"/assets/media/icons/duotune/arrows/arr075.svg"}})],1)]),_c('px-dropdown',{attrs:{"items":[
                  {
                    title: 'Edit',
                    action: function () { return _vm.actionEditTemplate(item); },
                  },
                  {
                    title: 'Delete',
                    action: function () { return _vm.actionDeleteTemplate(item); },
                  } ]}})],1)])])}),0)],1)]):_c('div',{staticClass:"text-center text-primary bg-light-primary rounded p-4 mt-4"},[_c('div',{staticClass:"fw-bold mb-1"},[_vm._v("No items yet... :(")]),_c('div',[_vm._v("Create some template...")])])])}
var staticRenderFns = []

export { render, staticRenderFns }