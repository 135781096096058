<template>
  <div>
    <div
      v-if="!filterText && filteredCategories.size"
      class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
    >
      <div class="my-5 my-lg-5">
        <div v-for="category in filteredCategories.keys()" :key="category">
          <div v-if="!isMapNotEmpty(filteredCategories.get(category))">
            <div :class="['menu-item menu-accordion', { show: category === innerCurrentCategory }]">
              <div class="menu-link py-2 px-0" @click.prevent="() => changeCurrentCategory(category)">
                <span class="menu-title fs-8 fw-semi-bold text-uppercase text-ls-sm text-muted">
                  {{ category }}
                </span>
                <span class="menu-arrow" />
              </div>
            </div>
            <div v-if="innerCurrentCategory === category">
              <div
                v-for="subCategory in filteredCategories.get(category).keys()"
                :class="['menu-item menu-accordion', { show: subCategory === innerCurrentSubCategory }]"
                :key="subCategory"
              >
                <div v-if="filteredCategories.get(category).get(subCategory).length">
                  <div class="menu-link py-2" @click.prevent="() => changeCurrentSubCategory(subCategory)">
                    <span class="menu-title fs-6">
                      {{ subCategory }}
                    </span>
                    <span class="menu-arrow" />
                  </div>
                  <draggable
                    v-if="subCategory === innerCurrentSubCategory"
                    class="mh-400px scroll-y"
                    handle=".menu-bullet"
                    :sort="false"
                    :list="filteredCategories.get(category).get(subCategory)"
                    :group="{
                      name: 'sub-category-items',
                      pull: 'clone',
                      put: false,
                    }"
                    :clone="cloneCallback"
                  >
                    <div
                      v-for="item in filteredCategories.get(category).get(subCategory)"
                      :key="item.id"
                      class="kt-items-list-item menu-item"
                    >
                      <td class="ghost-helper ghost-helper-d-table-cell ghost-helper-space-start" colspan="9" />
                      <div class="ghost-content px-4">
                        <span class="ghost-from-bullet">
                          <i class="bi bi-grip-vertical fs-3" />
                        </span>
                        <span class="ghost-title">
                          {{
                            item.type === 'pricing_item_template' ? item.attributes.name : item.attributes.english_name
                          }}
                        </span>
                      </div>
                      <div class="ghost-hidden position-relative">
                        <div class="handle d-flex align-items-start menu-link py-2 ps-6 pe-4" role="button">
                          <span class="menu-bullet">
                            <i class="bi bi-grip-vertical fs-3" />
                          </span>

                          <div class="d-flex flex-column">
                            <span class="menu-title fw-semi-bold pe-15">
                              {{
                                item.type === 'pricing_item_template'
                                  ? item.attributes.name
                                  : item.attributes.english_name
                              }}
                              <span
                                v-if="item.type === 'planting_item_template' && item.attributes.price_variables"
                                class="ghost-hidden"
                              >
                                <span
                                  v-if="
                                    item.attributes.price_variables.length &&
                                    item.attributes.price_variables.length !== 1 &&
                                    item.attributes.price_variables[0].id !== '0'
                                  "
                                  class="mx-2"
                                >
                                  •
                                </span>
                                <span
                                  v-if="
                                    item.attributes.price_variables.length &&
                                    item.attributes.price_variables.length !== 1 &&
                                    item.attributes.price_variables[0].id !== '0'
                                  "
                                >
                                  {{ item.attributes.price_variables[0].size }}
                                </span>
                              </span>
                            </span>
                            <span>
                              {{
                                $n(
                                  item.attributes.price_variables && item.attributes.price_variables.length
                                    ? item.attributes.price_variables[0].price
                                    : item.attributes.price,
                                  'currency',
                                  'en-CA'
                                )
                              }}
                            </span>
                            <div class="text-muted small text-truncate text-truncate-3">
                              {{ item.attributes.description }}
                            </div>
                          </div>
                        </div>
                        <div class="position-absolute top-0 end-0 d-flex align-items-center justify-content-end mt-2">
                          <px-btn
                            color="light-primary"
                            extended-classes="btn-icon w-30px h-30px p-0"
                            @click.native="() => actionAddingToLast(item)"
                          >
                            <span class="svg-icon svg-icon-2x">
                              <inline-svg src="/assets/media/icons/duotune/arrows/arr075.svg" />
                            </span>
                          </px-btn>
                          <px-dropdown
                            :items="[
                              {
                                title: 'Edit',
                                action: () => actionEditTemplate(item),
                              },
                              {
                                title: 'Delete',
                                action: () => actionDeleteTemplate(item),
                              },
                            ]"
                          />
                        </div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="filterText && searchCategories.length">
      <div class="my-5 my-lg-5">
        <draggable
          class="mh-400px scroll-y filtered-container"
          handle=".menu-bullet"
          :sort="false"
          :list="searchCategories"
          :group="{
            name: 'sub-category-items',
            pull: 'clone',
            put: false,
          }"
          :clone="cloneCallback"
          @scroll.native="handleOnSerachResultsScroll"
        >
          <div v-for="item in searchCategories" :key="item.id" class="kt-items-list-item menu-item">
            <td class="ghost-helper ghost-helper-d-table-cell ghost-helper-space-start" colspan="9" />
            <div class="ghost-content px-4">
              <span class="ghost-from-bullet">
                <i class="bi bi-grip-vertical fs-3" />
              </span>
              <span class="ghost-title">
                {{ item.type === 'pricing_item_template' ? item.attributes.name : item.attributes.english_name }}
              </span>
            </div>
            <div class="ghost-hidden position-relative">
              <div class="handle d-flex align-items-start menu-link py-2 ps-6 pe-4" role="button">
                <span class="menu-bullet">
                  <i class="bi bi-grip-vertical fs-3" />
                </span>

                <div class="d-flex flex-column">
                  <span class="menu-title fw-semi-bold pe-15">
                    {{ item.type === 'pricing_item_template' ? item.attributes.name : item.attributes.english_name }}
                    <span
                      v-if="item.type === 'planting_item_template' && item.attributes.price_variables"
                      class="ghost-hidden"
                    >
                      <span
                        v-if="
                          item.attributes.price_variables.length &&
                          item.attributes.price_variables.length !== 1 &&
                          item.attributes.price_variables[0].id !== '0'
                        "
                        class="mx-2"
                      >
                        •
                      </span>
                      <span
                        v-if="
                          item.attributes.price_variables.length &&
                          item.attributes.price_variables.length !== 1 &&
                          item.attributes.price_variables[0].id !== '0'
                        "
                      >
                        {{ item.attributes.price_variables[0].size }}
                      </span>
                    </span>
                  </span>
                  <span>
                    {{
                      $n(
                        item.attributes.price_variables && item.attributes.price_variables.length
                          ? item.attributes.price_variables[0].price
                          : item.attributes.price,
                        'currency',
                        'en-CA'
                      )
                    }}
                  </span>
                  <div class="text-muted small text-truncate text-truncate-3">
                    {{ item.attributes.description }}
                  </div>
                </div>
              </div>
              <div class="position-absolute top-0 end-0 d-flex align-items-center justify-content-end mt-2">
                <px-btn
                  color="light-primary"
                  extended-classes="btn-icon w-30px h-30px p-0"
                  @click.native="() => actionAddingToLast(item)"
                >
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="/assets/media/icons/duotune/arrows/arr075.svg" />
                  </span>
                </px-btn>
                <px-dropdown
                  :items="[
                    {
                      title: 'Edit',
                      action: () => actionEditTemplate(item),
                    },
                    {
                      title: 'Delete',
                      action: () => actionDeleteTemplate(item),
                    },
                  ]"
                />
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
    <div v-else class="text-center text-primary bg-light-primary rounded p-4 mt-4">
      <div class="fw-bold mb-1">No items yet... :(</div>
      <div>Create some template...</div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import draggable from 'vuedraggable';

export default {
  props: {
    categories: {
      type: [Map, Array],
      required: true,
    },
    scopeType: {
      type: String,
      default: '',
    },
    filterText: {
      type: String,
      default: '',
    },
    currentCategory: {
      type: String,
    },
    currentSubCategory: {
      type: String,
    },
    cloneCallback: {
      type: Function,
    },
    actionAddingToLast: {
      type: Function,
    },
    actionEditTemplate: {
      type: Function,
    },
    actionDeleteTemplate: {
      type: Function,
    },
  },
  components: { draggable },
  watch: {
    filterText() {
      this.page = 1;
      setTimeout(() => {
        const el = this.$el.querySelector('.filtered-container');
        if (el) {
          el.scrollTop = 0;
        }
      }, 500);
    },
    categories() {
      this.updateCategories();
    },
  },
  data() {
    return {
      perPage: 10,
      page: 1,
      innerCurrentCategory: null,
      innerCurrentSubCategory: null,
      initCategoriesList: [],
    };
  },
  computed: {
    filteredCategories() {
      const newCategories = cloneDeep(this.categories);

      newCategories.forEach((category, categoryKey) => {
        category.forEach((subCategory, subCategoryKey) => {
          const newItems = [];

          subCategory.forEach((el) => {
            const name = el.attributes[el.type === 'pricing_item_template' ? 'name' : 'english_name'] || '';
            const description = el.attributes.description || '';

            if (
              name.toString().toLowerCase().includes(this.filterText.toLowerCase()) ||
              description.toLowerCase().includes(this.filterText.toLowerCase())
            ) {
              newItems.push(el);
            }
          });

          newCategories.get(categoryKey).set(subCategoryKey, newItems);
        });
      });

      let newMap = new Map();

      switch (this.scopeType) {
        case 'Landscaping':
          newMap.set('Landscape', newCategories.get('Landscape'));
          newMap.set('Pool', newCategories.get('Pool'));
          newMap.set('Woodwork', newCategories.get('Woodwork'));
          break;
        case 'Woodworking':
          newMap.set('Woodwork', newCategories.get('Woodwork'));
          newMap.set('Landscape', newCategories.get('Landscape'));
          newMap.set('Pool', newCategories.get('Pool'));
          break;
        case 'Swimming Pool':
          newMap.set('Pool', newCategories.get('Pool'));
          newMap.set('Landscape', newCategories.get('Landscape'));
          newMap.set('Woodwork', newCategories.get('Woodwork'));
          break;
        default:
          newMap = newCategories;
          break;
      }

      return newMap;
    },
    searchCategories() {
      const data = this.initCategoriesList.filter((el) => {
        const name = el.attributes[el.type === 'pricing_item_template' ? 'name' : 'english_name'] || '';
        const description = el.attributes.description || '';

        return (
          name.toString().toLowerCase().includes(this.filterText.toLowerCase()) ||
          description.toLowerCase().includes(this.filterText.toLowerCase())
        );
      });
      return data.slice(0, this.page * this.perPage);
    },
  },
  mounted() {
    this.updateCategories();
  },
  methods: {
    handleOnSerachResultsScroll(e) {
      const target = e.target;
      const delta = 100;
      // Check if the user has scrolled to the bottom
      if (target.scrollTop + target.offsetHeight + delta >= target.scrollHeight) {
        this.page++;
      }
    },
    updateCategories() {
      this.initCategoriesList = [];

      const newCategories = cloneDeep(this.categories);
      if (this.currentCategory) this.innerCurrentCategory = this.currentCategory;
      if (this.currentSubCategory) this.innerCurrentSubCategory = this.currentSubCategory;

      switch (this.scopeType) {
        case 'Landscaping':
          if (newCategories.has('Landscape')) {
            this.initCategoriesList = [
              ...this.initCategoriesList,
              ...this.itemsFromCategories(newCategories, 'Landscape'),
            ];
          }
          if (newCategories.has('Pool')) {
            this.initCategoriesList = [...this.initCategoriesList, ...this.itemsFromCategories(newCategories, 'Pool')];
          }
          if (newCategories.has('Woodwork')) {
            this.initCategoriesList = [
              ...this.initCategoriesList,
              ...this.itemsFromCategories(newCategories, 'Woodwork'),
            ];
          }
          break;
        case 'Woodworking':
          if (newCategories.has('Woodwork')) {
            this.initCategoriesList = [
              ...this.initCategoriesList,
              ...this.itemsFromCategories(newCategories, 'Woodwork'),
            ];
          }
          if (newCategories.has('Landscape')) {
            this.initCategoriesList = [
              ...this.initCategoriesList,
              ...this.itemsFromCategories(newCategories, 'Landscape'),
            ];
          }
          if (newCategories.has('Pool')) {
            this.initCategoriesList = [...this.initCategoriesList, ...this.itemsFromCategories(newCategories, 'Pool')];
          }
          break;
        case 'Swimming Pool':
          if (newCategories.has('Pool')) {
            this.initCategoriesList = [...this.initCategoriesList, ...this.itemsFromCategories(newCategories, 'Pool')];
          }
          if (newCategories.has('Landscape')) {
            this.initCategoriesList = [
              ...this.initCategoriesList,
              ...this.itemsFromCategories(newCategories, 'Landscape'),
            ];
          }
          if (newCategories.has('Woodwork')) {
            this.initCategoriesList = [
              ...this.initCategoriesList,
              ...this.itemsFromCategories(newCategories, 'Woodwork'),
            ];
          }
          break;
        default:
          this.initCategoriesList = [...this.initCategoriesList, ...this.itemsFromCategories(newCategories)];
          break;
      }
    },
    changeCurrentCategory(category) {
      this.innerCurrentCategory = category === this.innerCurrentCategory ? null : category;
    },
    changeCurrentSubCategory(subCategory) {
      this.innerCurrentSubCategory = subCategory === this.innerCurrentSubCategory ? null : subCategory;
    },
    isMapNotEmpty(map) {
      let isEmpty = true;

      if (map) {
        map.forEach((category, categoryKey) => {
          if (map.get(categoryKey).length && isEmpty) {
            isEmpty = false;
          }
        });
      }

      return isEmpty;
    },
    itemsFromCategories(items, category) {
      const searchCategories = [];

      if (category) {
        items.get(category).forEach((subCategory) => {
          subCategory.forEach((el) => {
            searchCategories.push(el);
          });
        });
      } else {
        items.forEach((i, key) => {
          items.get(key).forEach((subCategory) => {
            subCategory.forEach((el) => {
              searchCategories.push(el);
            });
          });
        });
      }

      return searchCategories;
    },
  },
};
</script>
